<template>
  <div class="news">
    <div class="a-profile-subpage-title -news">
      <div>
        <h1 class="w-color-orange-diploms">{{ $t("news") }}</h1>
        <p class="w-color-black">{{ $t("news-overview") }}</p>
      </div>
      <div class="z-icon-wrapper -large">
        <lightningIcon />
      </div>
    </div>
    <NewsTable
      v-if="newPosts.length > 0"
      :news="newPosts"
      :title="$t('news-from-last-login')"
    />
    <NewsTable
      v-if="seenPosts.length > 0"
      :news="seenPosts"
      :title="$t('news')"
    />
    <b-button
      variant="primary"
      class="mx-auto -wide a-poppins-btn -medium"
      @click="loadMore"
      v-if="showLoadMore"
    >
      {{ $t("load-more") }}</b-button
    >
  </div>
</template>

<script>
import axios from "/utils/axios";

import lightningIcon from "/assets/icons/lightning.svg?inline";

const PAGE_SIZE = 10;

const api = {
  getPosts: async (user, loadedCount) => {
    const userCreatedAt = new Date(user.createdAt);
    const userSeenPostsIds = user.seenPosts.map(({ id }) => id);

    let newPosts = await axios.getAuth("posts", {
      params: {
        filters: {
          createdAt: {
            $gte: userCreatedAt,
          },
          id: {
            $notIn: userSeenPostsIds,
          },
        },
        populate: {
          picture: true,
          button: true,
        },
        sort: ["createdAt:desc"],
        pagination: {
          start: 0,
          limit: PAGE_SIZE,
        },
      },
    });

    const toLoad = newPosts.data.length
      ? PAGE_SIZE - newPosts.data.length
      : PAGE_SIZE;

    const seenPosts = toLoad
      ? await axios.getAuth("posts", {
          params: {
            filters: {
              seenBy: {
                id: user.id,
              },
            },
            populate: {
              picture: true,
              button: true,
            },
            sort: ["createdAt:desc"],
            pagination: {
              start: loadedCount.seen + loadedCount.new,
              limit: toLoad,
            },
          },
        })
      : { data: [] };

    return [newPosts, seenPosts];
  },
  updateSeen: async (ids) => {
    return axios.postAuth("post/update", { ids });
  },
};

export default {
  layout: "profile",

  components: {
    lightningIcon,
    NewsTable: () => import("/components/News/NewsTable.vue"),
  },

  data() {
    return {
      newPosts: [],
      seenPosts: [],
      showModal: false,
      loadedCount: { new: 0, seen: 0 },
      showLoadMore: true,
    };
  },

  async created() {
    await this._loadNews();
  },

  methods: {
    async _loadNews() {
      const user = await this.$store.dispatch("auth/user");
      let [newPosts, seenPosts] = await api.getPosts(user, this.loadedCount);

      const totalCount =
        newPosts.meta.pagination.total +
        (seenPosts.data.length > 0 ? seenPosts.meta.pagination.total : 1);

      const ids = newPosts.data.map((post) => post.id);
      newPosts = newPosts.data.map((post) => post.attributes);
      seenPosts = seenPosts.data.map((post) => post.attributes);

      if (newPosts.length > 0) {
        this.newPosts = [...this.newPosts, ...newPosts];
      }
      this.seenPosts = [...this.seenPosts, ...seenPosts];

      const isLastPage =
        this.newPosts.length + this.seenPosts.length === totalCount;
      if (isLastPage) {
        this.showLoadMore = false;
      }

      this.loadedCount.new = this.newPosts.length;
      this.loadedCount.seen = this.seenPosts.length;

      await api.updateSeen(ids);
    },

    async loadMore() {
      await this._loadNews();
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
</style>
